import React from "react";
function MenuComponent () {

    const teppanArray = [
        {title:"鉄板"},
        {name: "ピリ辛きゅうり", price : "450"},
        {name: "山芋短冊", price : "450"},
        {name: "スライストマト", price : "400"},
        {name: "ポテトサラダ", price : "400"},
        {name: "だし巻き卵", price : "650"},

        
    ];

    const obanzaiArray = [
        {name : "黒毛和牛リブロース", price : "3000"},
        {name : "黒毛和牛ヒレ ", price : "3800"},
        {name : "蝦夷あわび", price : "1500"},
        {name : "ホタテバター焼き", price : "1500"},
        {name : "海老鉄板焼き ", price : "1500"},


    ];

    const drinkArray = [
        {name : "サッポロ黒ラベル(生)", price : "600"},
        {name : "焼酎(麦・芋)", price : "480"},
        {name : "サワー", price : "450"},
        {name : "日本酒(冷・燗)", price : "500~"},
        {name : "ワイン(赤・白)", price : "500"},

    ];



 return (
<div>
    <h3 className="font-bold">MENU</h3>
<div className="md:flex md:flex-wrap block">
 <div className="my-3 md:w-full">
    <h3>一品</h3>

    <table className="w-4/5 md:w-1/4 m-auto text-xl">
     <tbody>
        {teppanArray.map((teppan,index) =>(
            <tr className="leading-loose" key={index}>
                <td className="text-left">{teppan.name}</td>
                <td className="text-left">{teppan.price}</td>
            </tr>
        ))}
      </tbody>
    </table>
</div>




<div className="my-3 md:w-full">
    <h3>鉄板焼き</h3>

    <table className="w-4/5 md:w-1/4 m-auto text-xl">
    <tbody>
        {obanzaiArray.map((obanzai,index) =>(
            <tr className="leading-loose" key={index}>
                <td className="text-left">{obanzai.name}</td>
                <td className="text-left">{obanzai.price}</td>

            </tr>
        ))}
    </tbody>
    </table>

 </div>

 <div className="my-3 md:w-full">
        <h3>飲み物</h3>
    <table className="w-4/5 md:w-1/4 m-auto text-xl">
    <tbody>
        {drinkArray.map((drink,index) =>(
            <tr className="leading-loose" key={index}>
                <td className="text-left">{drink.name}</td>
                <td className="text-left">{drink.price}</td>

            </tr>
        ))}
    </tbody>
</table>


 </div>




</div>

<small>こちらはメニューの一部になります。</small>
<br />
<small>詳細はgoogle mapなどからもご確認いただけます。</small>

</div>
)
}

export default MenuComponent ;