function Top () {
    return (
        <div>

        <div className="w-4/5 md:w-1/2 m-auto">
            <img src="/assets/vegetable.png" alt="" />
        </div>

        <h1 className="font-bold">鉄板とおばんざい <br /> IRODORI</h1>

        <div className="w-4/5 md:w-1/2 m-auto pt-3">
            <p>当店は野菜や牛肉、海鮮などの旬の食材を鉄板焼きやおばんざいで提供しています。季節のお酒と共にお楽しみください！</p>
        </div>


        </div>
    )
}

export default Top;