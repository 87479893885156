import Calling from "./Calling";
function BusinessHour () {
    return (
        <div className="py-10">
            <h2 className="text-lg font-bold">営業時間</h2>
            <p>18:00~26:00</p>
            <p>不定休</p>

            <Calling />


        </div>
    )
}

export default BusinessHour;