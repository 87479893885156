import './App.css';
import Header from './sections/Header'
import Top from './sections/Top';
import BusinessHour from './sections/BusinessHour';
import MenuComponent from './Components/MenuComponent';
import QuestionsComponent from './Components/QuestionsComponent';
import Access from './sections/Access';


function App() {
  return (
    <div className="App bg-gray-300">
      <Header />
      <Top />
      <BusinessHour />
      <MenuComponent />
      <QuestionsComponent />
      <Access />
    </div>
  );
}

export default App;
