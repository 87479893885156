
function Header () {
    return (
        <header className="sticky top-0 bg-slate-400">
            <div className="w-1/12 m-auto">
                <img src="/assets/logo.png" alt="" />
            </div>
        </header>
    )
}

export default Header;