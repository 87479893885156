function QuestionsComponent () {

    const Questions = [
        {question : "クレジットカードの使用は可能ですか？", answer : "いいえ。現金のみです。"},
        {question : "喫煙は可能でしょうか？", answer : "全席禁煙となっております。"},
        {question : "駐車場はありますか？", answer : "ございません。"},
        {question : "予約は可能でしょうか？", answer : "お電話にて受け付けております。"},


    ];

    return (
        <div className="mt-32">
            <h3 className="font-bold">よくある質問</h3>

            {Questions.map((faq)=> (
                <ul className="py-3 leading-loose">
                    <li className="underline">Q.{faq.question}</li>
                    <li>A.{faq.answer}</li>
                </ul>
            ))}

        </div>
    )
}

export default QuestionsComponent;