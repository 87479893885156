import Maps from "./Maps";
function Access () {
    return (
        <div className="mt-16">
            <h3 className="font-bold">アクセス</h3>
            <ul className="leading-loose py-3">
                <li>〒616-8227</li>
                <li>京都府京都市右京区常盤草木町3-4</li>
                <li>嵐電常盤駅徒歩3分</li>

            </ul>

            <Maps />
        </div>
    )
}


export default Access;